@font-face {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 300;
/*	src: local('Helvetica Neue'), local('HelveticaNeue'), */
	src: url('./HelveticaNeue.eot');
	src: url('./HelveticaNeue.eot?#iefix') format('embedded-opentype'), url('./HelveticaNeue.woff2') format('woff2'), url('./HelveticaNeue.woff') format('woff'), url('./HelveticaNeue.ttf') format('truetype');
}
@font-face {
	font-family: 'HelveticaNeue-Light';
	font-style: normal;
	font-weight: 100;
/*	src: local('Helvetica Neue Light'), local('HelveticaNeue-Light'), */
	src: url('./HelveticaNeue-Light.eot');
	src: url('./HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'), url('./HelveticaNeue-Light.woff2') format('woff2'), url('./HelveticaNeue-Light.woff') format('woff'), url('./HelveticaNeue-Light.ttf') format('truetype');
}
@font-face {
	font-family: 'HelveticaNeue-Medium';
	font-style: normal;
	font-weight: 400;
/*	src: local('Helvetica Neue Medium'), local('HelveticaNeue-Medium'), */
	src: url('./HelveticaNeue-Medium.eot');
	src: url('./HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'), url('./HelveticaNeue-Medium.woff2') format('woff2'), url('./HelveticaNeue-Medium.woff') format('woff'), url('./HelveticaNeue-Medium.ttf') format('truetype');
}
@font-face {
	font-family: 'HelveticaNeue-Bold';
	font-style: normal;
	font-weight: 800;
/*	src: local('Helvetica Neue Bold'), local('HelveticaNeue-Bold'), */
	src: url('./HelveticaNeue-Bold.eot');
	src: url('./HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'), url('./HelveticaNeue-Bold.woff2') format('woff2'), url('./HelveticaNeue-Bold.woff') format('woff'), url('./HelveticaNeue-Bold.ttf') format('truetype');
}
