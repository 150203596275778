element-image {
	display:flex;
	position:relative;
	align-items:center;
	justify-content:center;
	overflow:hidden;
	min-width: auto;
	min-height: auto;
}

element-image > [block-content="legend"] {
	position:absolute;
	font-size:0.8rem;
	line-height:1rem;
	bottom:0;
	left:0;
	right:0;
	text-align:center;
}
element-image.lqip-reveal > img {
	transition: 0.3s filter linear;
}
element-image.lqip > img {
	filter: blur(6px);
	filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='6'); /* old ie support */
}

element-image.lazy-reveal > img {
	transition: 0.1s opacity linear;
	opacity:1;
}
element-image.lazy > img {
	opacity:0;
}
@keyframes element-image-spinner {
	to {
		transform: rotate(360deg);
	}
}

element-image.lazy:after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 30px;
	height: 30px;
	margin-top: -15px;
	margin-left: -15px;
	border-radius: 50%;
	border: 1px solid #ddd;
	border-top-color: #aaa;
	animation: element-image-spinner 3s ease infinite;
	opacity: 0.75;
}

element-image.error:after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 6px;
	height: 6px;
	margin-top: -3px;
	margin-left: -3px;
	border-radius: 50%;
	border: 1px solid #fdd;
	opacity: 0.75;
}

element-image[data-fit="contain"],
element-image[data-fit="contain"] > img {
	width:100%;
}
element-image[data-fit="cover"],
element-image[data-fit="cover"] > img {
	width:100%;
	height:100%;
}
element-image[data-fit="contain"] > img {
	object-fit: contain;
}
element-image[data-fit="cover"] > img {
	object-fit: cover;
}

element-image > img {
	display:block;
	flex:0 0 auto;
	align-self:center; /* not stretch */
}

element-image[data-position="left top"] {

}
element-image[data-position="left top"] > img {
	object-position:left top;
}
element-image[data-position="left center"] {
	height:100%;
}
element-image[data-position="left center"] > img {
	object-position:left center;
	height:100%;
}
element-image[data-position="left bottom"] {
	height:100%;
}
element-image[data-position="left bottom"] > img {
	object-position:left bottom;
	height:100%;
}
element-image[data-position="center top"] > img {
	object-position:center top;
}
element-image[data-position="center center"] {
	height:100%;
}
element-image[data-position="center center"] > img {
	object-position:center center;
	height:100%;
}
element-image[data-position="center bottom"] {
	height:100%;
}
element-image[data-position="center bottom"] > img {
	object-position:center bottom;
	height:100%;
}
element-image[data-position="right top"] > img {
	object-position:right top;
}
element-image[data-position="right center"] {
	height:100%;
}
element-image[data-position="right center"] > img {
	height:100%;
	object-position:right center;
}
element-image[data-position="right bottom"] {
	height:100%;
}
element-image[data-position="right bottom"] > img {
	height:100%;
	object-position:right bottom;
}

element-image[data-fit="none"],
element-image[data-fit="none"] > img {
	max-width:100%;
	max-height:100%;
	width: auto;
	height: auto;
}
element-image[data-fit="none"][data-position="left top"] {
	justify-content: flex-start;
	vertical-align:top;
}
element-image[data-fit="none"][data-position="right top"] {
	justify-content: flex-end;
	vertical-align:top;
}
element-image[data-fit="none"][data-position="center top"] {
	vertical-align:top;
}
element-image[data-fit="none"][data-position="left center"] {
	justify-content: flex-start;
	height:100%;
}
element-image[data-fit="none"][data-position="right center"] {
	justify-content: flex-end;
	vertical-align:middle;
	height:100%;
}
element-image[data-fit="none"][data-position="center center"] {
	vertical-align:middle;
	height:100%;
}
element-image[data-fit="none"][data-position="left bottom"] {
	justify-content: flex-start;
	height:100%;
}
element-image[data-fit="none"][data-position="left bottom"] > img {
	align-self:flex-end;
}
element-image[data-fit="none"][data-position="right bottom"] {
	justify-content: flex-end;
	height:100%;
}
element-image[data-fit="none"][data-position="right bottom"] > img {
	align-self:flex-end;
}
element-image[data-fit="none"][data-position="center bottom"] {
	height:100%;
}
element-image[data-fit="none"][data-position="center bottom"] > img {
	align-self:flex-end;
}


element-image > [block-content="overlay"] {
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
}
