html {
	height:100%;
}
body {
	display:flex;
	flex-direction:column;
	min-height:100%;
}
[block-type="header"] {
	flex:none;
}
[block-type="main"] {
	flex:1 0 auto;
	flex-direction:column;
}
[block-type="footer"] {
	flex:none;
}

.layout,
.ui.container.layout {
	flex: 0 0 auto;
	display:flex;
}
.layout.column {
	flex-direction: column;
	align-items:stretch;
}
.layout.top {
	align-items: flex-start;
}
.layout.vcenter {
	align-items: center;
}
.layout.bottom {
	align-items: flex-end;
}
.layout.left {
	justify-content: flex-start;
}
.layout.hcenter {
	justify-content: center;
}
.layout.right {
	justify-content: flex-end;
}
.layout.column > img,
.layout.column.left > img {
	align-self:flex-start;
}
.layout.column.hcenter > img {
	align-self:center;
}
.layout.column.right > img {
	align-self:flex-end;
}

.layout.fullwidth {
	width:100%;
}

.inverted.layout {
	background: rgba(0, 0, 0, 0.8);
}

.inverted.layout p {
	color: white;
}

