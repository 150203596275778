/*general*/
html, body {
	font-size: 13px;
	font-family: 'HelveticaNeue', Arial, Helvetica, sans-serif;
	line-height:1em;
}
p {
	color:#1e1e1c;
}
a {
	color:#8a8a8a;
}
a:hover {
	color:#ff1408;
}
a:hover > img,
.item:hover > img {
	opacity:0.5;
}
h1 {
	font-size:24px;
}
h2 {
	font-size:19px;
}
h3 {
	font-size:17px;
}
h4 {
	font-size:14px;
}
h1, h2, h3, h4, h5, h6 {
	letter-spacing:3px;
	margin-top:0;
	margin-bottom:0;
	font-family: 'HelveticaNeue', Arial, Helvetica, sans-serif;
}
h1 strong, h2 strong, h3 strong, h4 strong {
	font-family: 'HelveticaNeue-Medium', Arial, Helvetica, sans-serif;
}
h1 .lighter, h2 .lighter, h3 .lighter, h4 .lighter {
	font-family: 'HelveticaNeue-Light', Arial, Helvetica, sans-serif;
}
p strong {
	font-family: 'HelveticaNeue-Bold', Arial, Helvetica, sans-serif;
}
p .lighter,
span[block-type="light"]  {
	font-family: 'HelveticaNeue-Light', Arial, Helvetica, sans-serif;
}
p {
	color: #adadad;
	letter-spacing:1px;
}
span.color {
	color:#ff1408;
}
p.justify.aligned {
	padding-right: 1em;
}
p.justify.aligned:last-child {
	padding-right: 0;
}


span.white.color {
	color:#fff;
}
span.blue.color {
	color:#00008ff;
}
span.red.color {
	color:#ff1408;
}
span.orange.color {
	color:#ffa500;
}
span.yellow.color {
	color:#ffff00;
}
span.green.color {
	color:#00ff00;
}
span.purple.color {
	color:#9370db;
}



/*end general*/





/*menus*/
.ui.menu {
	border: none;
	box-shadow: none;
	margin: 1rem 2rem;
	background:transparent;
}
.ui.menu .ui.dropdown.item,
.ui.menu .item {
	font-family:'HelveticaNeue-Medium', Arial, Helvetica, sans-serif;
	text-transform:uppercase;
	letter-spacing:4px;
/*	padding:0 1em;*/
	background: none !important;
	color:#1e1e1c;
}
.ui.menu .ui.dropdown.item > .menu {
	background:rgba(255, 255, 255, 0.9) !important;
	padding:0.75em;
	border: 1px solid rgba(210, 210, 210, 0.8);
	box-shadow:
		4px 0px 8px -4px rgba(200, 200, 200, 0.8),
		-4px 0px 8px -4px rgba(200, 200, 200, 0.8),
		0px 4px 8px -4px rgba(200, 200, 200, 0.8) !important;
	border-radius: 0;
}
.ui.menu .ui.dropdown .menu > .item:hover,
.ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover {
	color:#ff1408 !important;
	background:transparent !important;
}
.ui.menu .ui.dropdown .menu > .item {
	text-transform:uppercase !important;
	letter-spacing:2px;
	line-height: 1em;
	padding: 0.25em 0.5em !important;
	color:#1e1e1c !important;
}
.ui.menu .item:before {
	width: 0;
}

.ui.menu .ui.dropdown .menu > .active.item {
	background:transparent !important;
}
.ui.menu .ui.dropdown .menu > .active.item:hover {
	color:#1e1e1c !important;
}
/*end menu*/



/*header*/
header .ui.grid {
	padding-top:1em;
	background:transparent;
}
element-sticky.header:not([data-mode="start"]) > header .ui.grid {
	padding-top:0em;
	background:rgba(255, 255, 255, 0.4);
	transition: all .4s ease-in-out 0s;
}


header .ui.grid p a {
	padding-left: 2em;
	line-height: 2em;
	font-size:17px;
	letter-spacing:3px;
	font-family: 'HelveticaNeue-Light', Arial, Helvetica, sans-serif;
	color:#1e1e1c;
}
header > .variant-h > .ui.grid {
	background:transparent;
/*	transition: background-color 0.5s ease;*/
}
element-sticky.header:not([data-mode="start"]) header > .variant-h > .ui.grid {
	background:rgba(255, 255, 255, 0.4);
}
element-sticky.header:not([data-mode="start"]) header > .variant-h > .ui.grid p a,
element-sticky.header:not([data-mode="start"]) header > .variant-h .ui.menu .ui.dropdown.item,
element-sticky.header:not([data-mode="start"]) header > .variant-h .ui.menu .item {
	color:#1e1e1c;
}
header > .variant-h > .ui.grid p a,
header > .variant-h .ui.menu .ui.dropdown.item,
header > .variant-h .ui.menu .item {
	color:#fff;
	transition: color 0.5s ease;
}
element-sticky[data-collapsed="false"].header {
	margin-bottom:3em;
}
element-sticky[data-collapsed="true"].header {
	margin-bottom:0;
}
element-sticky.header .column {
	margin-top:1em;
}

element-sticky.header .column .layout.fullwidth {
	height:1em !important;
}

@media (max-width: 991px) {
	.ui.menu .ui.dropdown.item, .ui.menu .item {
		letter-spacing:2px;
		padding:6px;
	}
	.ui.menu .ui.dropdown .menu > .item {
	letter-spacing:0;
}
	header .ui.grid p a {
		padding-left:1em;
	}
}
@media (max-width: 767px) {
	element-sticky.header .ui.stackable.grid > .column:not(.row) {
		padding-bottom:0 !important;
		padding-right: 0 !important;
	}
	header .ui.grid p a {
		padding-left:0;
	}
	.ui.menu .ui.dropdown.item, .ui.menu .item {
		font-size:11px;
	}
	header .ui.grid > .column:not(.row) {
		padding: 0 1rem !important;
	}
	header .ui.grid {
		margin-bottom:2em;
	}
	.ui.menu .ui.dropdown .menu > .item {
		line-height:2em;
	}
	header .ui.grid .layout.right {
		justify-content: flex-start;
	}
	header .ui.grid .layout.right .ui.menu {
		margin:0;
	}
	element-sticky.header .column {
		margin-top:0;
	}
	element-sticky.header .column .layout.fullwidth {
	padding-bottom:1.5rem;
}
}
@media (max-width: 521px) {
	.ui.menu .ui.dropdown.item, .ui.menu .item {
		letter-spacing:0;
	}
}
/*end header*/



/*button*/
.ui.button {
	background:transparent;
	font-family:'HelveticaNeue', Arial, Helvetica, sans-serif;
	color:#ff1408;
	border:1px solid #ff1408;
	padding:4px 16px;
	border-radius:0;
	font-size:10px;
	letter-spacing:1px;
	text-transform:uppercase;
}
.ui.button:hover {
	color:#fff;
	background:#ff1408;
}
.ui.button:active,
.ui.button:focus {
	color:#fff;
	background:#adadad;
	border:1px solid #adadad;
}

p.right.aligned .ui.button {
/*	float:right;*/
	color:#adadad;
	border:1px solid #adadad;
	margin-left:0.5em;
}
p.right.aligned .ui.button:hover {
	color:#fff;
	background:#adadad;
	border:1px solid #adadad;
}
.right.aligned .ui.button:active,
.right.aligned .ui.button:focus {
	color:#ff1408;
	background:#fff;
	border:1px solid #ff1408;
}
/*end button*/




/*horizontal nav*/
a.ui.button.icon i.icon.prev:before {
	background-image:url(./img/Fleche_gauche_22x22.svg);
	background-position:center;
	width:22px;
	height:22px;
}
a.ui.button.icon i.icon.next:before {
	background-image:url(./img/Fleche_droite_22x22.svg);
	background-position:center;
	width:22px;
	height:22px;
}
a.ui.button.icon i.icon {
	width:22px;
	height:22px;
}
a.ui.button.icon i.icon.up:before {
	background-image:url(./img/Croix_50x50.svg);
	background-position:center;
	width:30px;
	height:30px;
}
/*end horizontal nav*/






/*fullview*/

body .layout.container element-carousel > .ui.icon.button.fullview {
	position: relative;
	float: right;
	margin-top:1em;
	margin-right:1em;
}
body element-carousel > .ui.icon.button.fullview i.zoom.icon:before {
	content:' ' !important;
	background-image:url(./img/Agrandissement_20x20.svg) !important;
	background-position:center;
	width: 20px;
	height: 20px;
	float: right;
	opacity:0.7;
}
body element-carousel > .ui.icon.button.fullview:hover i.zoom.icon:before {
	opacity:1;
}

body.fullview element-carousel {
	z-index:9999;
}

body.fullview .layout.container element-carousel > .ui.icon.button.fullview {
	position: absolute;
	top:1em;
	right:1em;
	width:50px;
	height:50px;
	opacity:1;
	z-index:9;
}
body.fullview element-carousel > .ui.icon.button.fullview i.zoom.icon:before {
	content:' ' !important;
	background-image:url(./img/Croix_100x50.svg) !important;
	background-position:right center;
	background-repeat:no-repeat;
	width:50px;
	height:50px;
	opacity:1;
}
body.fullview element-carousel > .ui.icon.button.fullview:hover i.zoom.icon:before {
	background-position:left center;
}


body .layout.container element-carousel .flickity-page-dots {
	bottom:70px !important;
}
body.fullview .layout.container element-carousel .flickity-page-dots {
	bottom:35px !important;
}

/*end fullview*/






/*icon button*/
a.ui.button.icon {
	font-family:'HelveticaNeue-Bold', Arial, Helvetica, sans-serif;
	color:#ff1408;
/*	border:1px solid #ff1408;*/
	border:none;
	line-height:30px;
	padding:0;
	padding-left:10px;
	border-radius:0;
	font-size:10px;
	letter-spacing:1px;
	text-transform:uppercase;
/*	padding-left: 2em;*/
/*	background-color:#fff;*/
	opacity:0.7;
	margin:0;
}
a.ui.button.icon:hover,
a.ui.button.icon:active,
a.ui.button.icon:focus {
/*	color:#fff;*/
/*	background-color:#ff1408;*/
	background-color:transparent;
	opacity:1;
}



a.icon.item i.portfolio.icon:before {
	content:' ' !important;
	background-image:url(./img/Mosaique_gris_40x20.svg) !important;
	background-position:left center;
	width: 20px;
	height: 20px;
}
a.icon.item.active i.portfolio.icon:before,
a.icon.item i.portfolio.icon:active:before,
a.icon.item i.portfolio.icon:hover:before {
	background-position:right center;
}

a.icon.item i.medialist.icon:before {
	content:' ' !important;
	background-image:url(./img/Liste_gris_40x20.svg) !important;
	background-position:left center;
	width: 20px;
	height: 20px;
}
a.icon.item.active i.medialist.icon:before,
a.icon.item i.medialist.icon:active:before,
a.icon.item i.medialist.icon:hover:before {
	background-position:right center;
}

a.icon.item i.carousel.icon:before {
	content:' ' !important;
	background-image:url(./img/Une_image_gris_40x20.svg) !important;
	background-position:left center;
	width: 20px;
	height: 20px;
}
a.icon.item.active i.carousel.icon:before,
a.icon.item i.carousel.icon:active:before,
a.icon.item i.carousel.icon:hover:before {
	background-position:right center;
}





element-gallery[data-mode="carousel"] {
	position: absolute;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index:1001;
}

element-gallery element-carousel .flickity-prev-next-button {
	top:95vh;
}
element-gallery element-carousel-cell {
	height: 100vh;
}









/*variant e*/
body.fullpage .variant-e element-carousel {
	background:#ededed;
	position:relative;
}


.variant-e element-gallery element-carousel-cell {
	height: auto;
	background:#ededed;
	top:auto;
}
.variant-e element-carousel element-carousel-cell p {
	position: relative;
	bottom: auto;
	padding: 0;
}
.variant-e element-gallery[data-mode="carousel"] {
	height: 84vh;
	top: 10vh;
	background:#ededed;
}
.variant-e element-carousel {
	margin-top:0;
}
.variant-e element-carousel .flickity-prev-next-button.next,
.variant-e element-carousel .flickity-prev-next-button.previous {
	top:90%;
	position:absolute;
}

.variant-e element-gallery[data-mode="carousel"] i.portfolio.icon:before {
	content:' ' !important;
	background-image:url(./img/Mosaique_gris_20x20.svg) !important;
	width: 20px !important;
	height: 20px !important;
}
.variant-e element-gallery[data-mode="carousel"] i.portfolio.icon:active:before,
.variant-e element-gallery[data-mode="carousel"] i.portfolio.icon:hover:before {
	background-image:url(./img/Mosaique_20x20.svg) !important;
}
.variant-e element-gallery[data-mode="carousel"] i.portfolio.icon {
	z-index:1002;
	margin-top: 0 !important;
	margin-right: 0 !important;
}
.variant-e element-gallery .ui.icon.menu a.icon.item i.carousel.icon {
	display:block;
}



.variant-e element-carousel-cell > .content {
/*	position: relative;*/
	padding: 0 1em;
}
.variant-e element-carousel-cell > .media {
	max-width: 100%;
	padding-right:0;
}

@media only screen and (min-width: 991px) {
	.variant-e element-carousel-cell > .content {
		position: absolute;
		width: 227px;
		margin-left: 336px;
/*		margin-top: 5px;*/
	}
	.variant-e element-carousel-cell > .media {
		max-width: 900px;
		padding-right:227px;
	}
	.variant-e element-carousel {
		margin-top:3em;
	}
	.variant-e element-carousel .flickity-prev-next-button.next,
	.variant-e element-carousel .flickity-prev-next-button.previous {
		top:50%;
	}
}
/*fin variant e*/




















element-gallery .ui.icon.menu a.icon.item i.carousel.icon {
	display:none;
}
.ProseMirror element-gallery .ui.icon.menu a.icon.item i.carousel.icon,
.variant-e element-gallery element-portfolio[data-shape="wide"] .ui.icon.menu a.icon.item i.carousel.icon {
	display:block;
}

element-gallery .ui.icon.menu a.icon.item i.portfolio.icon {
	z-index:99999;
}




element-gallery[data-mode="carousel"] i.portfolio.icon {
	z-index:1002;
	margin-top: 5em !important;
	margin-right: 2em !important;
}

element-portfolio-item:hover a.itemlink {
	display: flex;
	align-items: center;
	justify-content: center;
	line-height:17px;
}

element-gallery .ui.menu .item {
	padding:0;
	padding-left:2px;
}



element-gallery[data-mode="carousel"] a.icon.item i.portfolio.icon:before {
	content:' ' !important;
	background-image:url(./img/Croix_50x50.svg) !important;
	width: 50px;
	height: 50px;
}
element-gallery[data-mode="carousel"] a.icon.item i.portfolio.icon:active:before,
element-gallery[data-mode="carousel"] a.icon.item i.portfolio.icon:hover:before {
	background-image:url(./img/Croix_50x50_hover.svg) !important;
}
element-gallery[data-mode="carousel"] a.icon.item i.medialist.icon:before {
	display:none;
}



element-portfolio-item > .content {
	display:none;
}
element-portfolio-item:hover > .content,
element-portfolio-item:active > .content,
element-portfolio-item:focus > .content {
	display:block;
	cursor:zoom-in;
}


element-gallery .ui.medialist element-image:hover {
	cursor:zoom-in;
}

/*element-portfolio-item:hover > .content,*/
/*element-portfolio-item:active > .content,*/
/*element-portfolio-item:focus > .content {*/
/*	cursor: pointer;*/
/*}*/

element-portfolio-item > .content a {
	font-size:13px;
	letter-spacing:3px;
	color:#fff;
	font-family: 'HelveticaNeue', Arial, Helvetica, sans-serif;
	padding:1em;
}
element-portfolio-item > .content a:hover,
element-portfolio-item > .content a:focus,
element-portfolio-item > .content a:active {
	color:#fff;
}

element-portfolio-item > .content .layout.fullwidth img {
	width:50px;
}
element-portfolio-item > .content a.itemlink:hover {
	color:#adadad;
	letter-spacing:1px;
}


@media screen and (max-width: 460px) {
	element-portfolio element-portfolio-item {
		padding-bottom:60%;
	}
	element-portfolio[data-shape="wide"] element-portfolio-item {
		padding-bottom:40%;
	}
	element-portfolio[data-shape="tall"] element-portfolio-item {
		padding-bottom:80%;
	}
}





element-portfolio[data-shape="small"] element-portfolio-item >.content p {
	padding:0 1rem;
	text-align:center;
}





element-portfolio.carousel .ui.tiny.menu {
	top:2em;
	right:1em;
}
element-portfolio.carousel {
	z-index:101;
}
element-portfolio.carousel element-carousel-cell {
	background:#1e1e1c;
}
element-portfolio .ui.tiny.menu {
	position: absolute;
	top: -3em;
	right: 0;
}
element-portfolio .ui.tiny.menu a {
	padding-left:0;
	padding-right:0.7em;
}
element-portfolio > .cells > .item {
	cursor:pointer;
}
/*end icon button*/



/*Help Tab*/
.variant-j h1,
.variant-j p {
	letter-spacing:0 !important;
	color:#1e1e1c !important;
}
.variant-j h1 {
	font-size:17px !important;
}
.variant-j p {
	font-size:11px !important;
}
.variant-j img {
	width:24px !important;
}
.variant-j .one.wide.column {
	text-align:center !important;
}
/*end Help Tab*/








/*gallery*/
element-gallery {
	width:100%;
}
/*end gallery*/






/*portfolio*/
element-portfolio {
	overflow:visible !important;
}
element-portfolio .item .layout p {
	color: white;
	font-size: 17px;
	letter-spacing:4px;
	line-height: 1em;
}
element-portfolio > .cells .item .layout p strong {
	font-family:'HelveticaNeue-Bold', Arial, Helvetica, sans-serif;
}
element-portfolio > .cells .item .layout p span.lighter {
	font-family:'HelveticaNeue-Light', Arial, Helvetica, sans-serif;
}
/*end portfolio*/




/*medialist*/
.ui.items.medialist > .item {
	padding: 2em 0;
	border-top:1px solid #adadad;
	margin:0;
}
.ui.items.medialist > .item:last-child {
	border-bottom:1px solid #adadad;
	padding: 2em 0;
	border-top:1px solid #adadad;
}
.ui.items.medialist > .item > .image {
	width:30%;
	height:200px;
}
@media (max-width: 1201px) and (min-width: 1025px) {
	.ui.items.medialist > .item > .image {
		height: 150px;
	}
}
@media (max-width: 1024px) and (min-width: 767px) {
	.ui.items.medialist > .item > .image {
		height: 120px;
	}
}


.ui.items.medialist > .item > .content p .ui.button {
	margin-top:1rem;;
}
.ui.items.medialist > .item > .content h3 {
	margin-bottom:1rem;;
}

.ui.items.medialist > .item > .image img {
	width:100%;
}


@media screen and (max-width: 767px) {
	.ui.items.medialist > .item > .content {
		width:100%;
		margin-left: -100%;
		margin-top: 26em;
		padding-left:0;
	}
	.ui.items.medialist > .item > .image {
		width:100% !important;
		height:24em
	}
}
/*end medialist*/









/*slider portfolio*/
element-portfolio > element-carousel > .flickity-prev-prev-button,
element-portfolio > element-carousel > .flickity-prev-next-button,
element-portfolio > element-carousel > .flickity-prev-next-button.previous,
element-portfolio > element-carousel > .flickity-prev-next-button.next {
	display:block;
}
element-portfolio > element-carousel .flickity-prev-prev-button,
element-portfolio > element-carousel .flickity-prev-next-button {
	top: inherit;
	bottom: 1em;
}
element-portfolio > element-carousel .flickity-prev-next-button.previous {
	left: 2em;
}
element-portfolio > element-carousel .flickity-prev-next-button.next {
	right:2em;
}
/*end slider portfolio*/










/*shutter*/
.ui.shutter > .column > [block-content="image"] {
	height: 100%;
	text-align:center;
}
.ui.shutter > .column > [block-content="image"] img {
	height: 100%;
}
.ui.shutter > .column > [block-content="text"] {
	padding:2em;
}
.ui.shutter > .column h1,
.ui.shutter > .column p {
	color:#fff;
}
.ui.shutter > .column.invert h1,
.ui.shutter > .column.invert p {
	color:#1e1e1c;
	}
.ui.shutter > .column h1 {
	text-transform:uppercase;
	font-family:'HelveticaNeue-Light', Arial, Helvetica, sans-serif;
	letter-spacing:3px;
}
.ui[class*="equal width"].shutter.grid > .column:not(.row) {
	height:100vh;
}
/*end shutter*/








/*carousel*/
.flickity-page-dots {
	bottom:20px !important;
}
.flickity-page-dots .dot {
	opacity: 0.75;
	background:#1e1e1c;
}
.flickity-page-dots .dot.is-selected {
	opacity: 0.5;
	background:#fff;
}
element-carousel .flickity-prev-next-button svg {
	display:none;
}
element-carousel .flickity-prev-next-button {
	top: inherit;
	bottom:1em;
	width: 35px;
	height: 35px;
	background:transparent;
}
element-carousel .flickity-prev-next-button.previous {
	background-image:url(./img/Fleche_gauche_45x45.svg) !important;
	background-position:center !important;
	left:2em;
}
element-carousel .flickity-prev-next-button.next {
	background-image:url(./img/Fleche_droite_45x45.svg) !important;
	background-position:center !important;
	right:2em;
}
element-carousel .flickity-prev-next-button:hover {
	background: rgba(255, 255, 255, 0.4);
}



.layout.ui.container > element-carousel .flickity-prev-next-button {
	bottom:3.5em;
}
body.fullview .layout.ui.container > element-carousel .flickity-prev-next-button {
	bottom:1em;
	top:auto;
}


element-carousel a.itemlink > p {
	transform: translate(0, 1em);
	transition:.6s transform cubic-bezier(.19,1,.22,1);
	opacity:0;
	transition-duration:0.5s;
}

element-carousel a.itemlink:hover > p,
element-carousel a.itemlink[block-focused] > p {
	transform: translateY(0);
	transition-duration: 1s;
	opacity:1;
}


element-carousel a.itemlink > *[block-type="heading"] {
	transform: translateY(2em);
	transition:.6s transform cubic-bezier(.19,1,.22,1);
	transition-duration:2s;
}

element-carousel a.itemlink:hover > *[block-type="heading"],
element-carousel a.itemlink[block-focused] > *[block-type="heading"] {
	transform: translateY(0);
	transition-duration: 2s;
}
/*end carousel*/

/*home*/
element-carousel element-carousel-cell a.itemlink {
/*	position:absolute;*/
/*	bottom:12%;*/
	padding-left:3em;
	padding-right:3em;
	margin-top:75vh; /* give lots of room for heading */
	width:100%;
	height:auto;
	overflow:hidden;
}
element-carousel element-carousel-cell a.itemlink > *[block-type="heading"] {
	margin-bottom: 10px;
	font-family: 'HelveticaNeue-Light', Helvetica, Arial, sans-serif;
	letter-spacing: 3px;
	text-transform: uppercase;
	font-size: 19px;
	color: #fff;
	min-height:30px;
	text-align:left;
	opacity:0.9;
}
element-carousel element-carousel-cell a.itemlink > p {
	font-family: 'HelveticaNeue', Helvetica, Arial, sans-serif;
	color:#fff;
	font-size:15px;
	text-transform: none;
	line-height:20px;
}
element-carousel element-carousel-cell a.itemlink > p > span {
	opacity:0.9;
}
element-carousel element-carousel-cell a.itemlink:hover,
element-carousel element-carousel-cell a.itemlink[block-focused] {
	color: #fff;
}
element-carousel element-carousel-cell a.itemlink p.left.aligned {
	text-align:left;
}






/*@media (max-width: 320px) {*/
/*	element-carousel element-carousel-cell a.itemlink {*/
/*		padding-left:0.5em;*/
/*		padding-right:0.5em;*/
/*	}*/
/*}*/
@media (max-width: 800px) {
	element-carousel element-carousel-cell a.itemlink {
		padding-left:1em;
		padding-right:1em;
/*		padding-top:60vh;*/
/*		margin-top:0;*/
	}
	element-carousel element-carousel-cell a.itemlink > *[block-type="heading"] {
		letter-spacing: 1px;
		font-size: 17px;
	}
	element-carousel element-carousel-cell a.itemlink > p {
		font-size:15px;
		line-height:17px;
	}
/*	element-carousel-cell {*/
/*		width: 100% !important;*/
/*	}*/
}



@media (max-height: 640px) {
	element-carousel element-carousel-cell a.itemlink {
		margin-top:70vh;
	}
}
@media (max-height: 560px) {
	element-carousel element-carousel-cell a.itemlink {
		margin-top:60vh;
	}
}
@media (max-height: 480px) {
	element-carousel element-carousel-cell a.itemlink {
		margin-top:50vh;
	}
}
@media (max-height: 400px) {
	element-carousel element-carousel-cell a.itemlink {
		margin-top:40vh;
	}
}
@media (max-height: 320px) {
	element-carousel element-carousel-cell a.itemlink {
		margin-top:30vh;
	}
}
/*end home*/





/*grid*/
.grid .column h1, .grid .column h2, .grid .column h3, .grid .column h4 {
	letter-spacing:3px;
}
/*.grid .column h1 strong, .grid .column h2 strong, .grid .column h3 strong, .grid .column h4 strong {*/
/*	font-family: 'HelveticaNeue-Medium', Arial, Helvetica, sans-serif;*/
/*}*/
/*.grid .column h1 .lighter, .grid .column h2 .lighter, .grid .column h3 .lighter, .grid .column h4 .lighter {*/
/*	font-family: 'HelveticaNeue-Light', Arial, Helvetica, sans-serif;*/
/*}*/
/*.grid .column p {*/
/*	color: #adadad;*/
/*	letter-spacing:1px;*/
/*}*/
/*.grid .column p strong {*/
/*	font-family: 'HelveticaNeue-Medium', Arial, Helvetica, sans-serif;*/
/*}*/
/*.grid .column p .lighter {*/
/*	font-family: 'HelveticaNeue-Light', Arial, Helvetica, sans-serif;*/
/*}*/
.grid .wide.column img {
	width:100%;
}
main[block-type="main"] > .layout.ui.container > [block-type="grid"] {
	border-top:1px solid #adadad;
	margin-right:0;
	margin-left:0;
	width:100%;
	margin-top:0;
	margin-bottom: 0;
}
/*main[block-type="main"] > .layout.ui.container > [block-type="grid"]:last-child {*/
/*	border-bottom:1px solid #adadad;*/
/*}*/
main[block-type="main"] > .layout.ui.container > [block-type="grid"] .column {
	padding:2em  0em;
}
@media (min-width: 767px) {
	main[block-type="main"] > .layout.ui.container > [block-type="grid"] .column img {
	padding-right: 1em;
	}
	main[block-type="main"] > .layout.ui.container > [block-type="grid"] .column element-image:not([data-fit="none"]) {
		max-height:200px;
	}
}
@media (max-width: 767px) {
	main[block-type="main"] > .layout.ui.container > [block-type="grid"] .column {
		height:100%;
	}
}
@media (min-width: 767px) and (max-width: 1024px) {
	main[block-type="main"] > .layout.ui.container > [block-type="grid"] .column element-image:not([data-fit="none"]) {
		max-height:120px;
	}
}
@media (min-width: 1025px) and (max-width: 1201px) {
	main[block-type="main"] > .layout.ui.container > [block-type="grid"] .column element-image:not([data-fit="none"]) {
		max-height:150px;
	}
}

.variant-b .ui.grid {
	width:100%
}



main .grid > .column > h3 {
	margin-bottom:1rem;
}


/*end grid*/










/*half page*/
.variant-c > .ui.grid > .column:first-child > .layout.fullwidth >.ui.image{
		width: 100%;
	}
.variant-c > .ui.grid > .column:last-child > .layout.fullwidth > .ui.image {
	margin-right:1em;
}
.variant-c .ui.grid .column p {
	color:#1e1e1c;
}
.variant-c .ui.grid .ui.grid {
	margin-bottom:0;
}

.variant-c > .ui.grid > .column > a.ui.button.icon {
	line-height:50px;
	float: right;
/*	margin-right: 2em;*/
	margin-bottom: 6em;
	opacity:1;
}
.variant-c > .ui.grid > .column > a.ui.button.icon i.icon.up {
	width:50px;
	height:50px;
}
.variant-c > .ui.grid > .column > a.ui.button.icon i.icon.up:before {
	background-image:url(./img/Croix_100x50.svg);
	background-position:right center;
	background-repeat:no-repeat;
	width:50px;
	height:50px;
}
.variant-c > .ui.grid > .column > a.ui.button.icon:hover i.icon.up:before {
	background-position:left center;
}
.variant-c .variant-f .ui.grid > .column > h2,
.variant-c .variant-f .ui.grid > .column > h3,
.variant-c .variant-f .ui.grid > .column > h4,
.variant-c .variant-f .ui.grid > .column > h5 {
	padding-top:5px;
	padding-right:15px;
}



	.variant-c element-portfolio-item:hover > .content,
	.variant-c element-portfolio-item:active > .content,
	.variant-c element-portfolio-item:focus > .content {
	display:none;
}





@media (max-width: 767px) {
	.variant-c .variant-f .ui.grid > .column > h2,
	.variant-c .variant-f .ui.grid > .column > h3,
	.variant-c .variant-f .ui.grid > .column > h4,
	.variant-c .variant-f .ui.grid > .column > h5 {
		padding-left:15px;
	}

	.variant-c .variant-f .ui.grid > .column > .layout {
		padding-right:15px;
	}
	.variant-c > .ui.grid > .column:first-child > .layout.fullwidth {
		padding-top:15px;
	}
}

@media (min-width: 767px) {
	.variant-c > .ui.grid > .column:first-child > .layout.fullwidth {
		width: 50%;
		height: 100vh !important;
		position: fixed;
		padding-right: 1rem;
	}
	.variant-c > .ui.grid > .column:last-child {
		margin-right:2em;
		margin-left:2em;
		margin-bottom:1em;
		margin-top:2em;
	}
}
.variant-c > .ui.grid > .column:first-child > .layout > a.ui.button.icon {
	line-height:50px;
	opacity:0.7;
}
.variant-c > .ui.grid > .column:first-child > .layout > a.ui.button.icon:hover {
	opacity:1;
}
.variant-c > .ui.grid > .column:first-child > .layout > a.ui.button.icon i.icon.up:before {
	background-image:url(./img/Fleche_Retour_50x50.svg);
	background-position:center;
	width:50px;
	height:50px;
}
.variant-c > .ui.grid > .column:first-child > .layout > p {
	margin-left: 30px;
	margin-bottom: 2px;
	color:#fff;
	font-size:11px;
}
.variant-c > .ui.grid > .column:first-child > .layout.left.bottom.row {
	bottom: 2em;
	left: 2em;
}
/*end half page*/



/*half block*/
.variant-d {
	display: list-item;
	margin-top:1rem;
	margin-bottom:10rem;
}
.variant-d .ui.grid .column {
	padding: 0 !important;
	background:#ededed;
}
.variant-d .ui.grid .column img {
	width:100%;
}
.variant-d .ui.grid .column p {
	font-size:15px;
}
.variant-d .ui.grid .column:first-child {
	line-height:0;
}
.variant-d .ui.grid .column:last-child {
	padding: 2em !important;
}
.variant-d .ui.grid .column p,
.variant-d .ui.grid .column p strong {
	color:#1e1e1c;
	line-height:1.7em;
}
.variant-d .ui.grid .ui.button {
	color:#fff;
	background:#ff1408;
	border:1px solid #ff1408;
}
.variant-d .ui.grid .ui.button:hover {
	color:#ff1408;
	background:transparent;
}
@media (max-width: 768px) {
	.variant-d .ui.grid .column:last-child {
		padding: 1em !important;
	}
}
/*end half block*/









/*Breadcrumb*/
.variant-f {
/*	margin-top: 2em;*/
	width:100%;
}
.ui.breadcrumb .divider:before {
	font-size: 5px;
	color: #000;
	padding: 2px;
}

.ui.breadcrumb {
	line-height: 3em;
	font-size: 11px;
}
.ui.breadcrumb .active.section,
.ui.breadcrumb a {
	text-transform:uppercase;
	font-family: 'HelveticaNeue-Bold', Arial, Helvetica, sans-serif;
	font-weight: normal;
	letter-spacing:1px;
}
.ui.breadcrumb .active.section {
	color:#ff1408 !important;
}
.ui.breadcrumb a {
	color:#1e1e1c;
}
.ui.breadcrumb a:focus,
.ui.breadcrumb a:hover,
.ui.breadcrumb a:active {
	color:#8a8a8a;
}

@media only screen and (max-width: 767px) {
	.ui.breadcrumb {
		line-height:2em;
	}
	.variant-f > .ui.doubling.stackable.equal.width.grid > .column {
		padding:1em 0 !important;
		padding-top: 0 !important;
	}
	.variant-f > .ui.doubling.stackable.equal.width.grid > .column:first-child {
		padding-bottom: 0 !important;
	}
}




.variant-f a.ui.button.icon i.icon.prev:before {
	background-image:url(./img/Fleche_gauche_22x22r.svg);
	background-position:center;
	width:22px;
	height:22px;
}
.variant-f a.ui.button.icon i.icon.next:before {
	background-image:url(./img/Fleche_droite_22x22r.svg);
	background-position:center;
	width:22px;
	height:22px;
}

.variant-c .variant-f  a.ui.button.icon i.icon.prev:before {
	background-image:url(./img/Fleche_gauche_22x22.svg);
	background-position:center;
	width:22px;
	height:22px;
}
.variant-c .variant-f  a.ui.button.icon i.icon.next:before {
	background-image:url(./img/Fleche_droite_22x22.svg);
	background-position:center;
	width:22px;
	height:22px;
}




/*end Breadcrumb*/



/*sitemap*/
.ui.list[block-type="sitemap"] .list > .item {
	margin-left: 2em !important;
}
element-sitepage {
	padding-left:5px;
}
/*end sitemap*/



/*map*/
.variant-a element-embed.ui.embed {
	height:340px;
}
.variant-a  p a {
	color:#ff1408;
}
.variant-a  p a:hover,
.variant-a  p a:focus {
	color:#1e1e1c;
}

@media (min-width: 767px) {
	.variant-a element-embed.ui.embed {
		max-width:92%;
	}
}
@media (max-width: 767px) {
	.variant-a .ui.stackable.grid {
		width:100%;
	}
}
/*end map*/
/*form*/
.variant-a .ui.form .field label {
	letter-spacing:1px;
	color:#1e1e1c;
}
.variant-a .ui.form .field input {
	width:100%;
	border:none;
	border-bottom: 1px solid #adadad;
	border-radius:0;
}
.variant-a .ui.form textarea {
	border:none;
	border-bottom: 1px solid #adadad;
	border-radius:0;
}
.variant-a .ui.form .fields.error .field textarea,
.variant-a .ui.form .fields.error .field select,
.variant-a .ui.form .fields.error .field input:not([type]),
.variant-a .ui.form .fields.error .field input[type="date"],
.variant-a .ui.form .fields.error .field input[type="datetime-local"],
.variant-a .ui.form .fields.error .field input[type="email"],
.variant-a .ui.form .fields.error .field input[type="number"],
.variant-a .ui.form .fields.error .field input[type="password"],
.variant-a .ui.form .fields.error .field input[type="search"],
.variant-a .ui.form .fields.error .field input[type="tel"],
.variant-a .ui.form .fields.error .field input[type="time"],
.variant-a .ui.form .fields.error .field input[type="text"],
.variant-a .ui.form .fields.error .field input[type="file"],
.variant-a .ui.form .fields.error .field input[type="url"],
.variant-a .ui.form .field.error textarea,
.variant-a .ui.form .field.error select,
.variant-a .ui.form .field.error input:not([type]),
.variant-a .ui.form .field.error input[type="date"],
.variant-a .ui.form .field.error input[type="datetime-local"],
.variant-a .ui.form .field.error input[type="email"],
.variant-a .ui.form .field.error input[type="number"],
.variant-a .ui.form .field.error input[type="password"],
.variant-a .ui.form .field.error input[type="search"],
.variant-a .ui.form .field.error input[type="tel"],
.variant-a .ui.form .field.error input[type="time"],
.variant-a .ui.form .field.error input[type="text"],
.variant-a .ui.form .field.error input[type="file"],
.variant-a .ui.form .field.error input[type="url"] {
	background: #fff;
	border-color: transparent;
	border-bottom: 1px solid #ff1408;
	color: #adadad;
}
.variant-a .ui.form .field.error textarea:focus,
.variant-a .ui.form .field.error select:focus,
.variant-a .ui.form .field.error input:not([type]):focus,
.variant-a .ui.form .field.error input[type="date"]:focus,
.variant-a .ui.form .field.error input[type="datetime-local"]:focus,
.variant-a .ui.form .field.error input[type="email"]:focus,
.variant-a .ui.form .field.error input[type="number"]:focus,
.variant-a .ui.form .field.error input[type="password"]:focus,
.variant-a .ui.form .field.error input[type="search"]:focus,
.variant-a .ui.form .field.error input[type="tel"]:focus,
.variant-a .ui.form .field.error input[type="time"]:focus,
.variant-a .ui.form .field.error input[type="text"]:focus,
.variant-a .ui.form .field.error input[type="file"]:focus,
.variant-a .ui.form .field.error input[type="url"]:focus {
	background: #fff;
	border-color: transparent;
	border-bottom: 1px solid #1e1e1c;
}
.variant-a .ui.message.success {
	border-radius: 0;
	background: #fff;
	box-shadow:none;
	border:1px solid #ededed;
}
.variant-a .ui.error.message {
	border-radius: 0;
	background: #fff;
	box-shadow:none;
	border:1px solid #ff1408;
}



.variant-a .ui.form textarea:not([rows]) {
	height:10em;
}
.variant-a .ui.form .field.field input:-webkit-autofill {
	box-shadow: 0px 0px 0px 100px #fff inset !important;
	border-color: #1e1e1c !important;
}
.variant-a form.success button[type="submit"] {
	display:none;
}
/*end form*/





/*video*/
.layout.ui.container element-embed,
.layout.fullwidth element-embed {
	width:100%;
	height:100%;
}
/*end video*/


/*divider*/

.ui.divider.hidden.section {
	display:block !important;
	height:6rem;
}

/*end divider*/









/*footer*/
footer[block-type="footer"] {
	background:#ededed;
	padding:2em;
}
footer[block-type="footer"] p.center.aligned a {
	padding:0 1em;
	font-family:'HelveticaNeue-Medium', Arial, Helvetica, sans-serif;
}
footer[block-type="footer"] p.center.aligned {
	margin-bottom:0;
}
/*end footer*/


/* carousel transition */
.transition a.itemlink,
.transition a.itemlink * {
	transition:none !important;
}
.transition:not(.blinds-from):not(.blinds-to) > * {
	background:white;
}
.transition.blinds-from > .transition-from[block-type="main"] {
	z-index:2;
}
.transition.blinds-from > .transition-from element-carousel-cell > .media {
	transform: translate(0, 0);
}
.transition.blinds-from > .transition-from element-carousel-cell > .content {
	opacity:1;
}
.transition.blinds-from > .transition-from element-carousel-cell {
	overflow:hidden;
	background:transparent;
}
.transition.blinds-from > .transition-from element-carousel > :not(.flickity-viewport) {
	display:none;
}

.transition.blinds-from.transitioning > .transition-from element-carousel-cell > .media {
	transform: translate(100%, 0);
	transition: transform 1.5s ease-in;
}
.transition.blinds-from.transitioning > .transition-from element-carousel-cell > .content {
	opacity:0;
	transition: opacity 0.5s;
}
.transition.blinds-from > .transition-to:not([block-type="main"]) {
	opacity:0.5;
}
.transition.blinds-from.transitioning > .transition-to:not([block-type="main"]) {
	opacity:1;
	transition: opacity 1.5s;
}

.transition.blinds-to > [block-type="main"],
.transition.blinds-from > [block-type="main"] {
	transform: translate(0, 0) !important;
}
.transition.blinds-to > .transition-to[block-type="main"] {
	z-index:3;
}
.transition.blinds-to > .transition-to element-carousel-cell > .media {
	transform: translate(100%, 0);
}
.transition.blinds-to > .transition-to element-carousel-cell > .content {
	opacity:0;
}
.transition.blinds-to > .transition-to element-carousel-cell {
	overflow:hidden;
	background:transparent;
}
.transition.blinds-to > .transition-to element-carousel > :not(.flickity-viewport) {
	display:none;
}

.transition.blinds-to.transitioning > .transition-to element-carousel-cell > .media {
	transform: translate(0, 0);
	transition: transform 1.5s ease-in;
}
.transition.blinds-to.transitioning > .transition-to element-carousel-cell > .content {
	opacity:1;
	transition: opacity 0.5s ease 1.5s;
}
.transition.blinds-to > .transition-to:not([block-type="main"]) {
	opacity:0;
}
.transition.blinds-to.transitioning > .transition-to:not([block-type="main"]) {
	transition: opacity 1.5s;
	opacity:1;
}
/* end carousel transition */

/* progress bar */

body:after {
	background:#EDEDED;
}

html.error > body:after {
	background:#FF1408;
}



/*resaux sociaux sur home*/
body.transition .variant-i {
	opacity:0;
}
.variant-i {
	position:fixed;
	bottom:32px;
	left:7rem;
	right:7rem;
}
.variant-i p {
	background:rgba(5, 5, 5, 0.35);
	height:30px;
	border-radius:30px;
	line-height:28px;
}
.variant-i p a {
	color:#fff;
	font-family: 'HelveticaNeue-Bold', Arial, Helvetica, sans-serif;
	margin:0 15px;
}
.variant-i p a:hover {
	opacity:0.5;
}
.variant-i p a.icon[block-type="link"] {
	padding-left:25px;
}
.variant-i p a img {
	height:17px;
}
@media (max-width: 991px) {
	.variant-i .ui.menu {
	margin:0;
	padding:8px;
	}
}
@media (max-width: 480px) {
	.variant-i .ui.menu .item {
		padding:4px;
	}
	.variant-i .ui.menu .item img {
		height:16px;
	}
}
.variant-i .ui.menu:last-child {
		background:rgba(5, 5, 5, 0.35);
		height:30px;
		border-radius:30px;
		line-height:28px;
		min-height:32px;
	}
/*end resaux sociaux sur home*/



/*traduction*/
.goog-te-menu-frame {box-shadow:none !important;}
.goog-te-menu-frame .goog-te-menu2-item div,
.goog-te-menu-frame .goog-te-menu2-item:link div,
.goog-te-menu-frame .goog-te-menu2-item:visited div,
.goog-te-menu-frame .goog-te-menu2-item:active div {
	color:black !important;
	font-family:'HelveticaNeue', Arial, Helvetica, sans-serif !important;
}
.goog-te-menu-frame .goog-te-menu2-item:hover div {background:#ededed !important;color:black !important;}
.goog-te-menu-frame .goog-te-menu2 {border:none !important;}
/*end traduction*/
