fieldset.plain {
	border-width:0;
	padding-right:0;
	padding-left:0;
}
textarea::-webkit-selection,
input::-webkit-selection {
	background-color: rgba(100, 100, 100, 0.4);
	color: rgba(0, 0, 0, 0.87);
}
textarea::-moz-selection,
input::-moz-selection {
	background-color: rgba(100, 100, 100, 0.4);
	color: rgba(0, 0, 0, 0.87);
}
textarea::selection,
input::selection {
	background-color: rgba(100, 100, 100, 0.4);
	color: rgba(0, 0, 0, 0.87);
}

.ui.checkbox input + label[for] {
	cursor:pointer;
}

element-select {
	z-index:1; /* or else dropdown menu goes under what's next */
}

form[data-authfield] button[type="submit"]:before {
	content:'🔒';
}

.ui.checkbox input[value="true"][type="checkbox"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 1em;
	box-shadow: inset -1em 0px 1px 0px #8A8A8A;
	border: 1px solid #8A8A8A;
	transition: 0.2s;
	opacity:0.5 !important;
	width:2.2em !important;
	height:1.2em;
}
.ui.checkbox input[value="true"][type="checkbox"]:checked {
	box-shadow: inset 1em 0px 1px 0px #3EA9E8;
	border: 1px solid #3EA9E8;
}
.ui.checkbox input[value="true"][type="checkbox"] + label:before {
	background:none;
	border:none;
}
.ui.checkbox input[value="true"][type="checkbox"] + label:after {
	content:'';
}
.ui.checkbox input[value="true"][type="checkbox"] + label {
	padding-left:2.65714em;
}

