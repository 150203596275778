.ui.items.medialist {
	margin:0;
}
.ui.items.medialist > .item {
	min-height:4em;
}
.medialist > .item > .image > .image {
	position: absolute;
	overflow: hidden;
	max-height: 100%;
	max-width: 100%;
}
