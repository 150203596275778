element-gallery {
	display:block;
	position:relative;
}
element-gallery > .menu {
	position:absolute;
	right:0;
	top:-3.1em;
}
i.portfolio.icon:before {
	content:'P';
}
i.medialist.icon:before {
	content:'L';
}
i.carousel.icon:before {
	content:'C';
}

element-gallery > [block-content="galleries"] > * {
	display:none;
}

element-gallery[data-mode="medialist"] > [block-content="galleries"] > [block-type="medialist"],
element-gallery[data-mode="portfolio"] > [block-content="galleries"] > [block-type="portfolio"],
element-gallery[data-mode="carousel"] > [block-content="galleries"] > [block-type="carousel"] {
	display:block;
}

a.itemlink {
	display: block;
	width:100%;
	height:100%;
	text-align: center;
	background-position: center center;
	background-repeat: no-repeat;
}

element-portfolio-item:hover a.itemlink,
element-carousel-item:hover a.itemlink {
	display:block;
}
