element-query {
	display:block;
	position:relative;
}

element-query > div > [block-content="template"] {
	display:none;
}

element-query[block-focused] > div > [block-content="template"] {
	display:block;
}

element-query[block-focused] > .results {
	display:none;
}
[block-id][block-type="query_template"]:before {
	content:'[';
}
[block-id][block-type="query_template"]:after {
	content:']';
}

element-query-tags {
	display:block;
}
element-query-tags > div {
	display:inline-block;
}
