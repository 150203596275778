[block-type="sitemap"] {
	min-height:2em;
	position:relative;
}
.ui.list[block-type="sitemap"] {
	padding:0.5em;
	margin:0.5em;
	box-shadow: -1px 1px 2px 1px grey;
}

[block-type="sitemap"] .list > .item > .content,
[block-type="sitemap"] > .item > .content {
	box-shadow: 0px 1px 1px 1px grey;
	padding-right: 0.5em;
	padding-bottom:1px;
	margin-bottom: 0px;
	min-height:1em;
}

.ui.list[block-type="sitemap"] .list > .item {
	margin-left:0.5em;
}

[block-type="sitemap"] .list.content.ui.accordion.active {
	margin-top:0;
	margin-bottom:1em;
	margin-left: 1em;
}
.ui.accordion[block-type="sitemap"] .title > a,
.ui.accordion[block-type="sitemap"] .accordion .title > a {
	padding-left:1.25em;
}
element-sitepage {
	display:block;
}
