element-carousel {
	display:block; /* mandatory for custom elements */
	position:relative;
	width:100%;
}
element-carousel .flickity-page-dots li.dot {
	pointer-events:auto; /* workaround prosemirror.css <= 0.24 style */
}
element-carousel .flickity-page-dots .dot {
	box-shadow: 0 0 3px white;
}
.inverted element-carousel .flickity-page-dots .dot {
	background:#CCC;
}

element-carousel-cell {
	display:block;
	background: transparent;
	min-height:2em;
	display:flex;
	align-items:center;
	flex-direction:column;
	justify-content:center;
}

element-carousel-cell > .media {
	width:100%;
	height:100%;
}

element-carousel-cell > .content {
	position: absolute;
	width:100%;
	height:100%;
}

.ProseMirror element-carousel-cell > .content {
	pointer-events:none;
}
.ProseMirror element-carousel-cell > .content > * {
	pointer-events:auto;
}

body.fullview {
	overflow:hidden;
}

body.fullview element-gallery > [block-content="galleries"] > :not(element-carousel) {
	display:none;
}
body.fullview element-carousel {
	display:block;
	position:fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	height:100%;
	background-color:black;
	z-index:1000;
}
body.fullview element-carousel element-carousel-cell {
	height:100vh !important;
}

element-carousel > .ui.icon.button.fullview {
	display:block;
	position:absolute;
	bottom:0;
	right:0;
}
i.zoom.icon:before {
	content:'Z';
}
@media screen and (max-width: 768px) {
	element-carousel-cell {
		width: 100% !important;
	}
}

/* Fade CSS */

element-carousel.fade > .flickity-viewport > .flickity-slider {
	transform: none !important;
	left:0 !important;
}

element-carousel.fade element-carousel-cell {
	left: 0 !important;
	opacity: 0;
	transition: opacity 0.6s ease-in-out 0.3s;
	z-index: 1;
}

element-carousel.fade element-carousel-cell.is-selected {
	opacity: 1;
	transition: opacity 0.6s ease-in-out;
	z-index: 2;
}

element-carousel.fade > .flickity-prev-next-button {
	z-index: 3;
}
