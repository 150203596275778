element-input-file > input[type="text"] + input[type="file"] {
	position:absolute !important;
	left:0 !important;
	top:0 !important;
	width:100% !important;
	height:100% !important;
	opacity:0 !important;
	padding:0 !important;
	margin:0 !important;
}
element-input-file {
	display:block;
	position:relative;
}
element-input-file > .ui.icon.button {
	z-index: 1;
}
element-input-file i.icon.upload:before {
	content:'⬆';
}
element-input-file > input[type="file"] + .ui.icon.button {
	display:none;
}
element-input-file > input[type="text"] + input[type="file"] + .ui.icon.button {
	display:block;
}
element-input-file .button > .upload {
	pointer-events:none;
}
element-input-file input[type="text"]:not([value]) ~ .button > .delete,
element-input-file input[type="text"][value=""] ~ .button > .delete,
element-input-file input[type="text"][value]:not([value=""]) ~ .button > .upload,
.loading.field element-input-file .button > .upload,
.success.field element-input-file .button > .upload {
	display:none;
}
element-input-file input[type="text"][value]:not([value=""]) ~ .button > .delete,
.loading.field element-input-file .button > .delete,
.success.field element-input-file .button > .delete {
	display:inline-block;
}
element-input-file > .ui.label {
	display:none;
}
.loading.field element-input-file > .ui.label {
	display:block;
}
